.fullscreen-video-container {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.fullscreen-video-container video {
  z-index: -1;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullscreen-video-content {
  background-color: #fffc;
  border-radius: 5px;
  padding: 30px;
}

.fullscreen-video-content h1 {
  color: #333;
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
}

.typewriter h1 {
  white-space: nowrap;
  letter-spacing: .15em;
  border-right: .15em solid orange;
  margin: 0 auto;
  animation: 3.5s steps(40, end) typing, .75s step-end 3.5s 0 blink-caret, .1s ease-in-out 3s forwards fade-out-caret;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: #0000;
  }

  50% {
    border-color: orange;
  }
}

#start-link {
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity .5s ease-in-out;
  animation: .5s ease-in-out 3.5s forwards fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out-caret {
  from {
    border-color: orange;
  }

  to {
    border-color: #0000;
  }
}
/*# sourceMappingURL=index.63e633b4.css.map */
