.fullscreen-video-container {

    position: relative;

    height: 100vh;

    width: 100vw;

    overflow: hidden;

    display: flex;

    justify-content: center;

    align-items: center;

}

.fullscreen-video-container video {

    position: absolute;

    width: auto;

    height: auto;

    min-width: 100%;

    min-height: 100%;

    top: 50%;

    left: 50%;

    transform: translate(-50%, -50%);

    z-index: -1;

}

.fullscreen-video-content {

    background-color: rgba(255, 255, 255, 0.8);

    padding: 30px;

    border-radius: 5px;

}

.fullscreen-video-content h1 {

    font-size: 3rem;

    font-weight: 700;

    margin: 0;

    color: #333;

}

.typewriter h1 {

    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    /* Adjust as needed */
    animation:
        typing 3.5s steps(40, end),
        blink-caret 0.75s step-end 3.5s 0,
        fade-out-caret 0.1s ease-in-out 3s forwards; 
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}

/* Fade-in link */
#start-link {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease-in-out;
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: 3.5s;
    /* Matches typewriter duration */
}

/* Keyframes to fade in the link */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        visibility: visible;
        opacity: 1;
    }
}

/* Keyframes to fade out the caret */
@keyframes fade-out-caret {
    from { border-color: orange; }
    to { border-color: transparent; }
}